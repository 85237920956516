import { CmsType, Content } from '@/models/CMS.model';
import React, { ReactNode, useEffect, useState } from 'react';
import SeoCMS from './SeoCMS';
import HeroBanner from '../homepage/HeroMenu/HeroBanner';
import ArticleCat from './ArticleCat';
import FlashSale from '../homepage/FlashSale';
import BundleImage from '../bundle/BundleImage';
import SkuProductskus from '../staticPage/SkuProductskus';
import BundlePage from '../bundle/BundlePage';
import UseCoupon from '../staticPage/UseCoupon';
import Default from '../staticPage/Default';
import Coupon from '../staticPage/Coupon';
import { useRouter } from 'next/router';
import { useAppDispatch } from '@/store/store';
import { useSelector } from 'react-redux';
import {
  clearResultGet1010,
  get1010CouponResultsSelector,
  isLoading1010CouponSelector,
  get1010CouponQuery,
} from '@/store/slices/get1010CouponSlice';
import { useTranslation } from 'next-i18next';
import * as showDialog from '@/utils/showDialog';
import { getRedirectQuery } from '@/utils/theone';
import Loading from '../Layout/handler/Loading';

interface Props {
  children: ReactNode;
  contents?: Content[];
}

export default function CmsWrapper({ children, contents }: Props) {
  let newContents: Content[] = [];
  let sortContents: Content[] = [];
  newContents = contents ? [...contents] : [];
  if (contents && contents.length > 0) {
    let hasMain = contents?.findIndex(
      (item) => item.type === CmsType.isCategoryPage,
    );
    if (hasMain < 0) {
      let hasInsertCategory: boolean = false;
      let articleIndex = newContents?.findIndex(
        (item) => item.type == 'article_cat',
      );
      if (articleIndex !== -1) {
        hasInsertCategory = true;
        const setRemoveObject = newContents.splice(articleIndex, 1)[0];
        sortContents = [...sortContents, setRemoveObject];
      }
      let seoIndex = newContents?.findIndex(
        (item) => item.type === CmsType.seoLandingPage,
      );
      if (seoIndex !== -1) {
        hasInsertCategory = true;
        const setRemoveObject = newContents.splice(seoIndex, 1)[0];
        sortContents = [...sortContents, setRemoveObject];
      }
      if (hasInsertCategory) {
        newContents = [
          ...newContents,
          {
            type: CmsType.isCategoryPage,
            detail: null,
            skus: [],
            color: null,
            colorSecond: null,
            keyword: null,
            isSlide: false,
            title: null,
          },
          ...sortContents,
        ];
      } else {
        newContents = [...newContents, ...sortContents];
      }
    }
  }

  return (
    <ActionWrapper>
      <div className="px-1 xl:px-0">
        {newContents ? (
          <>
            {newContents.map((e, i) => (
              <div key={i}>
                {e.type === CmsType.isCategoryPage ? (
                  children
                ) : (
                  <CMSContent content={e} />
                )}
              </div>
            ))}
            {!newContents.some((e) => e.type == CmsType.isCategoryPage) &&
              children}
          </>
        ) : (
          children
        )}
      </div>
    </ActionWrapper>
  );
}

const CMSContent = ({ content }: { content: Content }) => {
  return (
    <>
      {content.type === CmsType.heroBanner &&
        content.detailBanner &&
        content.detailBanner.length > 0 && (
          <div className="my-1">
            <HeroBanner
              name={content.title ?? 'cms'}
              banners={content.detailBanner ?? undefined}
            />
          </div>
        )}
      {content.type === CmsType.textDesc && (
        <div
          className="leading-none"
          dangerouslySetInnerHTML={{ __html: content.detailString || '' }}
        />
      )}
      {content.type === CmsType.articleCat && content.detailArticle && (
        <ArticleCat content={content.detailArticle} />
      )}
      {content.type === CmsType.seoLandingPage && (
        <SeoCMS content={content.detailString ?? undefined} />
      )}
      {content.type === CmsType.default &&
        (content && content.productskus && content.productskus.length > 0 ? (
          <>
            {content.detailDefault && content.detailDefault !== '' ? (
              <div
                className="leading-none my-1"
                dangerouslySetInnerHTML={{
                  __html: content.detailDefault || '',
                }}
              />
            ) : (
              ''
            )}

            <Default data={content} />
          </>
        ) : (
          <div
            className="leading-none my-1"
            dangerouslySetInnerHTML={{ __html: content.detailDefault || '' }}
          />
        ))}
      {content.type === CmsType.flashsale && content.detailFlashSale && (
        <FlashSale flashsale={content.detailFlashSale} />
      )}
      {content.type === CmsType.issueCoupon && content && (
        <UseCoupon title={content.title} />
      )}
      {content.type === CmsType.bundleimage && content.detailBundleImage && (
        <BundleImage
          data={content.detailBundleImage}
          isSlide={content.isSlide}
        />
      )}
      {content && content.type === CmsType.sku && content.skuProductskus && (
        <SkuProductskus
          data={content.skuProductskus}
          isSlide={content.isSlide}
          keyproduct={content.keyproduct}
          title={content.title}
        />
      )}
      {content && content.detailBundle && content.type === CmsType.bundle && (
        <BundlePage data={content.detailBundle} />
      )}
      {content && content.detailCoupon && content.type === CmsType.coupon && (
        <Coupon data={content} />
      )}
    </>
  );
};

export const ActionWrapper = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const [isClickGet1010coupon, setIsClickGet1010coupon] = useState(false);
  const get1010CouponResults = useSelector(get1010CouponResultsSelector);
  const isLoading1010Coupon = useSelector(isLoading1010CouponSelector);
  const { t, i18n } = useTranslation();
  const { t: t_product } = useTranslation('product');
  const router = useRouter();

  useEffect(() => {
    const handleClick = (event: any) => {
      if (
        event.target instanceof HTMLElement &&
        event.target.getAttribute('twd-click') !== null
      ) {
        const twdClickAttribute = event.target.getAttribute('twd-click');
        if (twdClickAttribute) {
          event.preventDefault();
          if (twdClickAttribute.includes('clickTopMenu')) {
            const regex = /'([^']+)'/;
            const match = twdClickAttribute.match(regex);
            if (match) {
              const extractedUrl = match[1];
              router.push(extractedUrl);
            }
          }
        }
      } else {
        const target = event.target;
        const parentElement = target.parentElement.parentElement;
        if (
          parentElement.classList.contains('btn') &&
          parentElement.hasAttribute('twd-click')
        ) {
          const twdClickAttribute = parentElement.getAttribute('twd-click');
          if (twdClickAttribute) {
            event.preventDefault();
            if (twdClickAttribute.includes('get1010coupon')) {
              setIsClickGet1010coupon(true);
              const regex = /\((\d+)\)/;
              const match = twdClickAttribute.match(regex);
              if (match) {
                const extractedNumber = match[1];
                dispatch(
                  get1010CouponQuery({
                    lang: i18n.language,
                    rewardid: extractedNumber,
                  }),
                );
              }
            }
          }
        }
      }
    };
    document
      .querySelector('.cms-wrapper')
      ?.addEventListener('click', handleClick);

    return () => {
      document
        .querySelector('.cms-wrapper')
        ?.removeEventListener('click', handleClick);
    };
  }, [router, dispatch, i18n]);
  useEffect(() => {
    if (!isLoading1010Coupon && isClickGet1010coupon) {
      if (get1010CouponResults.status === 401) {
        showDialog
          .showMessageWithButton({
            text: t('pleaseloginbeforegetcode'),
            primaryButton: {
              name: t('signin'),
              callBack: () => {
                router.push(
                  `/${router.locale}/signin${getRedirectQuery(router.asPath)}`,
                );
              },
            },
            secondaryButton: {
              name: t('cancel'),
            },
          })
          .then(() => {
            dispatch(clearResultGet1010());
          });
      } else {
        if (get1010CouponResults.iserror == false) {
          showDialog
            .showMessageWithButton({
              text: t('congratulationsyouhavecoupon'),
              image: '/images/svg/success_circle.svg',
              primaryButton: {
                name: t('viewcoupon'),
                callBack: () => {
                  router.push(`/${router.locale}/profile/myvoucher`);
                },
              },
              secondaryButton: {
                name: t_product('close'),
              },
            })
            .then(() => {
              dispatch(clearResultGet1010());
            });
        } else {
          showDialog
            .showErrorWithOkButton({
              text: `${get1010CouponResults.msg}`,
            })
            .then(() => {
              dispatch(clearResultGet1010());
            });
        }
      }
      setIsClickGet1010coupon(false);
    }
  }, [
    dispatch,
    isLoading1010Coupon,
    get1010CouponResults,
    router,
    t,
    t_product,
    isClickGet1010coupon,
  ]);
  return (
    <>
      {isLoading1010Coupon ? <Loading /> : ''}
      <div className="cms-wrapper">{children}</div>
    </>
  );
};
