import { Content } from '@/models/CMS.model';
import { ProductModel } from '@/models/Product.model';
import React, { useEffect, useMemo, useState } from 'react';
import SlickProductCard from '../common/SlickProductCard';
import { Grid, Skeleton } from '@mui/material';
import ProductCard from '../ProductCard';
interface Props {
  data: Content;
}
export default function Default({ data }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const slickComponentsSlide: JSX.Element[] = useMemo(() => [], []); // Initialize as empty array
  const slickComponents: JSX.Element[] = useMemo(() => [], []);
  const isSlide = data.isSlide ? data.isSlide : false;
  const color = data.color ? data.color : null;
  const colorSecond = data.colorSecond ? data.colorSecond : null;
  useEffect(() => {
    if (data && data.productskus && data.productskus.length > 0) {
      setIsLoading(false);
    }
  }, [
    data,
    isSlide,
    slickComponents,
    slickComponentsSlide,
    color,
    colorSecond,
  ]);
  return (
    <>
      {isLoading ? (
        <>
          <Grid container>
            <Grid item xs={6} lg={3} className="px-2 my-2">
              <Skeleton
                variant="rounded"
                style={{ width: '100%', height: '300px' }}
              />
            </Grid>
            <Grid item xs={6} lg={3} className="px-2 my-2">
              <Skeleton
                variant="rounded"
                style={{ width: '100%', height: '300px' }}
              />
            </Grid>
            <Grid item lg={3} className="px-2 my-2 lg:block xs:hidden">
              <Skeleton
                variant="rounded"
                style={{ width: '100%', height: '300px' }}
              />
            </Grid>
            <Grid item lg={3} className="px-2 my-2 lg:block xs:hidden">
              <Skeleton
                variant="rounded"
                style={{ width: '100%', height: '300px' }}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {isSlide ? (
            <div
              className="lg:px-4 xs:px-1"
              style={{ backgroundColor: color ? color : '' }}
            >
              <SlickProductCard
                products={data.productskus}
                slidesToShow={5}
                slidesToScroll={1}
                slidesToShowMobile={2}
                slidesToScrollMobile={1}
                autoplay={false}
                bgColor={colorSecond}
              />
            </div>
          ) : (
            <Grid
              container
              className="lg:px-4 xs:px-1"
              style={{ backgroundColor: color ? color : '' }}
            >
              {data.productskus &&
                data.productskus.map((element: ProductModel, index: number) => (
                  <Grid
                    key={`${index}`}
                    item
                    // xs={6}
                    // lg={3}
                    className="lg:p-2 xs:p-1"
                    // style={{
                    //   backgroundColor: colorSecond ? colorSecond : '',
                    // }}
                    sx={{
                      backgroundColor: colorSecond ? colorSecond : '',
                      width: {
                        lg: 'calc(100% / 5)', // width for large screens
                        xs: 'calc(100% / 2)', // width for large screens
                      },
                      padding: {
                        xs: '8px', // padding for extra small screens
                        lg: '16px', // padding for large screens
                      },
                    }}
                  >
                    <ProductCard product={element} />
                  </Grid>
                ))}
            </Grid>
          )}
        </>
      )}
    </>
  );
}
