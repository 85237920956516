import React from 'react';
import BundleCard from '../pdp/BundleCard';

interface Props {
  data: any;
}

export default function BundlePage({ data }: Props) {
  const datas = {
    products: data,
  };
  return <>{datas ? <BundleCard data={datas} /> : <></>}</>;
}
