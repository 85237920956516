import React from 'react';
import Image from 'next/image';
import { Article } from '@/models/HomePage.model';
import { Grid } from '@mui/material';
import { useTranslation } from 'next-i18next';
import AppLink from '../Layout/handler/AppLink';

export default function ArticleCat({
  content,
}: {
  content: Article[] | undefined;
}) {
  const { t } = useTranslation();
  return (
    <div>
      {content &&
        content.map((article, index) => (
          <div
            key={index}
            className="relative my-2 p-2 bg-white border rounded m-0"
          >
            <Grid container spacing={1}>
              {index % 2 != 0 ? (
                <>
                  <Grid item xs={6}>
                    <Image
                      src={article.img ?? ''}
                      alt={article.title ?? ''}
                      width={645}
                      height={384}
                    />
                  </Grid>
                  <Grid item xs={6} className="relative">
                    <div className="bg-white">
                      <div className="text-xl">{article.subject}</div>
                      <div
                        style={{
                          display: '-webkit-box',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 8,
                        }}
                      >
                        {article.title}
                      </div>
                    </div>
                    <div className="absolute right-2 bottom-0 z-10 text-primary cursor-pointer">
                      <AppLink href={article.slug}>
                        {t('readarticle')}
                        <i
                          className="bi bi-arrow-right"
                          style={{ verticalAlign: 'middle' }}
                        />
                      </AppLink>
                    </div>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={6} className="relative">
                    <div className="bg-white">
                      <div className="text-xl">{article.subject}</div>
                      <div
                        style={{
                          display: '-webkit-box',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 8,
                        }}
                      >
                        {article.title}
                      </div>
                    </div>
                    <div className="absolute right-2 bottom-0 z-10 text-primary cursor-pointer">
                      <AppLink href={article.slug}>
                        {t('readarticle')}
                        <i
                          className="bi bi-arrow-right"
                          style={{ verticalAlign: 'middle' }}
                        />
                      </AppLink>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <Image
                      src={article.img ?? ''}
                      alt={article.title ?? ''}
                      width={645}
                      height={384}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        ))}
    </div>
  );
}
