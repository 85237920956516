import { BundleModel, ProductBundle } from '@/models/Bundle.model';
import { Box, Grid, Skeleton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { ProductModelConverter } from '@/models/Product.model';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BundleImageCard from './BundleImageCard';
import styles from '../../styles/slickProductCard.module.css';
import { SkuWithQty } from '@/models/AddToCart.model';
import { useTranslation } from 'next-i18next';

interface Props {
  data: BundleModel[] | [];
  isSlide: Boolean;
}
function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <>
      <Box
        className={`bg-white shadow-lg cursor-pointer`}
        sx={{
          position: 'absolute',
          zIndex: 1,
          right: '-20px !important',
          top: '40%',
          transform: 'translateY(-50%)',
        }}
        onClick={onClick}
      >
        <Box
          className="h-6 w-6 sm:h-8 sm:w-8 p-1 sm:p-2"
          sx={{ lineHeight: '1rem' }}
        >
          <i className="bi bi-chevron-right"></i>
        </Box>
      </Box>
    </>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick } = props;
  return (
    <>
      <Box
        className={`bg-white shadow-lg cursor-pointer`}
        sx={{
          position: 'absolute',
          zIndex: 1,
          top: '40%',
          transform: 'translateY(-50%)',
          left: '-20px !important',
        }}
        onClick={onClick}
      >
        <Box
          className="h-6 w-6 sm:h-8 sm:w-8 p-1 sm:p-2"
          sx={{ lineHeight: '1rem' }}
        >
          <i className="bi bi-chevron-left"></i>
        </Box>
      </Box>
    </>
  );
}

export default function BundleImage({ data, isSlide }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const { i18n } = useTranslation();
  const sliderRef = useRef<Slider>(null);
  const settings = {
    infinite: data ? data.length > 3 : false,
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
          dotsClass: `slick-dots ${styles.dots}`,
        },
      },
    ],
  };

  useEffect(() => {
    const slickComponentsSlide: JSX.Element[] = [];
    const slickComponents: JSX.Element[] = [];

    if (data && data.length > 0) {
      data.forEach((element, index1) => {
        if (element.bundlesku && element.bundlesku.length > 0) {
          if (
            Array.isArray(element.bundlesku) &&
            element.bundlesku.length > 0
          ) {
            element.bundlesku.forEach((ele, index2) => {
              if (ele && Array.isArray(ele)) {
                const convertedProducts = ele.map((product: any) =>
                  ProductModelConverter.fromJson(product),
                );
                const allorgprice = ele[0].allorgprice;
                const allplppprice = ele[0].allplppprice;
                const setid = ele[0].setid;
                const skuQtyList: SkuWithQty[] = ele.map(
                  (eleChild: ProductBundle) => ({
                    sku: eleChild.skc?.toString() ?? '',
                    qty: eleChild.qntBundle ?? 1,
                  }),
                );

                if (isSlide) {
                  slickComponentsSlide.push(
                    <div key={`${index1}${index2}`}>
                      <BundleImageCard
                        index={`${index1}${index2}`}
                        element={element}
                        convertedProducts={convertedProducts}
                        allorgprice={allorgprice}
                        allplppprice={allplppprice}
                        setid={setid}
                        skuQtyList={skuQtyList}
                        isSlide={isSlide}
                      />
                    </div>,
                  );
                } else {
                  slickComponents.push(
                    <BundleImageCard
                      key={`${index1}${index2}`}
                      index={`${index1}${index2}`}
                      element={element}
                      convertedProducts={convertedProducts}
                      allorgprice={allorgprice}
                      allplppprice={allplppprice}
                      setid={setid}
                      skuQtyList={skuQtyList}
                      isSlide={isSlide}
                    />,
                  );
                }
              }
            });
          }
        }
      });
      setIsLoading(false);
    }

    setSlickComponents(slickComponents);
    setSlickComponentsSlide(slickComponentsSlide);
  }, [data, isSlide, i18n.language]);

  const [slickComponents, setSlickComponents] = useState<JSX.Element[]>([]);
  const [slickComponentsSlide, setSlickComponentsSlide] = useState<
    JSX.Element[]
  >([]);

  return (
    <>
      {isLoading ? (
        <>
          <Grid container>
            <Grid item xs={12} lg={4} className="px-2 my-2">
              <Skeleton
                variant="rounded"
                style={{ width: '100%', height: '300px' }}
              />
            </Grid>
            <Grid item lg={4} className="px-2 my-2 lg:block xs:hidden">
              <Skeleton
                variant="rounded"
                style={{ width: '100%', height: '300px' }}
              />
            </Grid>
            <Grid item lg={4} className="px-2 my-2 lg:block xs:hidden">
              <Skeleton
                variant="rounded"
                style={{ width: '100%', height: '300px' }}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container>{slickComponents}</Grid>
          <Slider ref={sliderRef} {...settings}>
            {slickComponentsSlide}
          </Slider>
        </>
      )}
    </>
  );
}
