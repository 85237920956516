import { ProductModel } from '@/models/Product.model';
import React, { useEffect, useRef, useState } from 'react';
import ProductCard from '../ProductCard';
import { Box, Grid, Skeleton } from '@mui/material';
import Slider from 'react-slick';
import styles from '../../styles/slickProductCard.module.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'swiper/swiper-bundle.css'; // Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react'; // Import Swiper React components

interface Props {
  data: { [key: string]: ProductModel[] };
  isSlide: boolean;
  keyproduct?: string[]; // Update the keyproduct type to string[]
  title: string | null;
}
function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <>
      <Box
        className={`bg-white shadow-lg cursor-pointer `}
        sx={{
          position: 'absolute',
          zIndex: 1,
          right: '-20px !important',
          top: '40%',
          transform: 'translateY(-50%)',
        }}
        onClick={onClick}
      >
        <Box
          className="h-6 w-6 sm:h-8 sm:w-8 p-1 sm:p-2"
          sx={{ lineHeight: '1rem' }}
        >
          <i className="bi bi-chevron-right"></i>
        </Box>
      </Box>
    </>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick } = props;
  return (
    <>
      <Box
        className={`bg-white shadow-lg cursor-pointer`}
        sx={{
          position: 'absolute',
          zIndex: 1,
          top: '40%',
          transform: 'translateY(-50%)',
          left: '-20px !important',
        }}
        onClick={onClick}
      >
        <Box
          className="h-6 w-6 sm:h-8 sm:w-8 p-1 sm:p-2"
          sx={{ lineHeight: '1rem' }}
        >
          <i className="bi bi-chevron-left"></i>
        </Box>
      </Box>
    </>
  );
}
export default function SkuProductskus({
  data,
  isSlide,
  keyproduct,
  title,
}: Props) {
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const sliderRef = useRef<Slider>(null);
  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
          dotsClass: `slick-dots ${styles.dots}`,
        },
      },
    ],
  };
  const showActive = (keyitem: string) => {
    setActiveCategory(keyitem);
  };
  // Show Verify modal
  useEffect(() => {
    if (keyproduct && keyproduct.length > 0) {
      showActive(`${keyproduct[0]}0`);
      setIsLoading(false);
    }
  }, [keyproduct]);
  return (
    <div className="my-2">
      {keyproduct && keyproduct.length > 0 ? (
        <>
          {title ? <h2>{title}</h2> : <></>}
          <Swiper spaceBetween={10} slidesPerView={'auto'}>
            {keyproduct.map((product, index) => (
              <SwiperSlide key={`${product}${index}`} style={{ width: 'auto' }}>
                <div
                  className={
                    activeCategory === `${product}${index}`
                      ? 'hover:bg-primary px-2 mr-2 my-1 text-white border-2 border-primary bg-primary'
                      : 'hover:bg-primary px-2 mr-2 my-1 text-primary border-2 border-primary hover:text-white'
                  }
                  style={{ cursor: 'pointer', borderRadius: '5px' }}
                  onClick={() => {
                    showActive(`${product}${index}`);
                  }}
                >
                  <span>{product}</span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      ) : (
        <></>
      )}
      {Object.keys(data).map((category, index) => (
        <div
          key={`${category}${index}`}
          data-keyitem={`${category}${index}`}
          className={
            activeCategory === `${category}${index}` ? 'block' : 'hidden'
          }
        >
          {isLoading ? (
            <Grid container>
              <Grid item xs={isSlide ? 12 : 6} lg={3} className="px-2 my-2">
                <Skeleton
                  variant="rounded"
                  style={{ width: '100%', height: '300px' }}
                />
              </Grid>
              <Grid
                item
                xs={isSlide ? 12 : 6}
                lg={3}
                className={
                  isSlide ? 'lg:block xs:hidden px-2 my-2' : 'px-2 my-2'
                }
              >
                <Skeleton
                  variant="rounded"
                  style={{ width: '100%', height: '300px' }}
                />
              </Grid>
              <Grid item lg={3} className="px-2 my-2 lg:block xs:hidden">
                <Skeleton
                  variant="rounded"
                  style={{ width: '100%', height: '300px' }}
                />
              </Grid>
              <Grid item lg={3} className="px-2 my-2 lg:block xs:hidden">
                <Skeleton
                  variant="rounded"
                  style={{ width: '100%', height: '300px' }}
                />
              </Grid>
            </Grid>
          ) : isSlide ? (
            <>
              <Slider ref={sliderRef} {...settings}>
                {data[category]
                  .sort((a, b) => a.seq - b.seq)
                  .map((item, index2) => (
                    <Grid
                      key={`${category}${item.seq}${index2}`}
                      item
                      xs={12}
                      lg={12}
                      className="px-2 my-2"
                    >
                      <ProductCard product={item} />
                    </Grid>
                  ))}
              </Slider>
            </>
          ) : (
            <>
              <Grid container>
                {data[category]
                  .sort((a, b) => a.seq - b.seq)
                  .map((item, index2) => (
                    <Grid
                      key={`${category}${item.seq}${index2}`}
                      item
                      // xs={6}
                      // lg={3}
                      className="px-2 my-2"
                      sx={{
                        width: {
                          lg: 'calc(100% / 5)', // width for large screens
                          xs: 'calc(100% / 2)', // width for large screens
                        },
                        padding: {
                          xs: '8px', // padding for extra small screens
                          lg: '16px', // padding for large screens
                        },
                      }}
                    >
                      <ProductCard product={item} />
                    </Grid>
                  ))}
              </Grid>
            </>
          )}
        </div>
      ))}
    </div>
  );
}
