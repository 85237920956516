import { Reward } from '@/models/profile/MyVoucherDetail.model';
import React, { useEffect, useState } from 'react';
import styles from '../../styles/coupon.module.css';
import Image from 'next/image';
import { Button } from '@mui/material';
import { useTranslation } from 'next-i18next';
import {
  clearResultGet1010,
  get1010CouponResultsSelector,
  isLoading1010CouponSelector,
  get1010CouponQuery,
} from '@/store/slices/get1010CouponSlice';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@/store/store';
import { useRouter } from 'next/router';
import * as showDialog from '@/utils/showDialog';
import { getRedirectQuery } from '@/utils/theone';

interface Props {
  data: Reward;
}
export default function CouponCard({ data }: Props) {
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [isClickGet1010coupon, setIsClickGet1010coupon] = useState(false);
  const get1010CouponResults = useSelector(get1010CouponResultsSelector);
  const isLoading1010Coupon = useSelector(isLoading1010CouponSelector);
  const getCoupon = (id: string) => {
    dispatch(
      get1010CouponQuery({
        lang: i18n.language,
        rewardid: id,
      }),
    );
    setIsClickGet1010coupon(true);
  };
  useEffect(() => {
    if (!isLoading1010Coupon && isClickGet1010coupon) {
      if (get1010CouponResults.status === 401) {
        showDialog
          .showMessageWithButton({
            text: t('pleaseloginbeforegetcode'),
            primaryButton: {
              name: t('signin'),
              callBack: () => {
                router.push(
                  `/${router.locale}/signin${getRedirectQuery(router.asPath)}`,
                );
              },
            },
            secondaryButton: {
              name: t('cancel'),
            },
          })
          .then(() => {
            dispatch(clearResultGet1010());
          });
      } else {
        if (get1010CouponResults.iserror == false) {
          showDialog
            .showMessageWithButton({
              text: t('congratulationsyouhavecoupon'),
              image: '/images/svg/success_circle.svg',
              primaryButton: {
                name: t('viewcoupon'),
                callBack: () => {
                  router.push(`/${router.locale}/profile/myvoucher`);
                },
              },
              secondaryButton: {
                name: t('close'),
              },
            })
            .then(() => {
              dispatch(clearResultGet1010());
            });
        } else {
          showDialog
            .showErrorWithOkButton({
              text: `${get1010CouponResults.msg}`,
            })
            .then(() => {
              dispatch(clearResultGet1010());
            });
        }
      }
      setIsClickGet1010coupon(false);
    }
  }, [
    dispatch,
    isLoading1010Coupon,
    get1010CouponResults,
    router,
    t,
    isClickGet1010coupon,
  ]);
  return (
    <>
      <div className={`${styles.couponwrap} bg-secondaryPurple`}>
        <div
          className="coupon-left text-center bg-white flex"
          style={{ alignItems: 'center' }}
        >
          <Image
            src={data.image ? data.image : '/images/logo/logoSquare.webp'}
            alt="logo"
            className={`${styles.couponimg} inline lg:p-3 xs:pl-1`}
            width={100}
            height={100}
            style={{ alignItems: 'center' }}
          />
        </div>
        <div className="px-2 py-1 text-white">
          <span className="">
            <span className="pr-2 lg:text:md xs:text-xs">
              {t('coupondiscount')}
            </span>
            <span className="f-kitti75 couponprice">
              <span className="lg:text-lg xs:text-md pr-1">
                {data.couponUnit == '฿' ? data.couponUnit : ''}
              </span>
              <span className="lg:text-4xl xs:text-lg font-bold">
                {data.couponAmount ? data.couponAmount : ''}
              </span>
              <span className="lg:text-4xl xs:text-lg">
                {data.couponUnit == '%' ? data.couponUnit : '.-'}
              </span>
            </span>
          </span>
          <div className={`${styles.coupondesc}  pr-2 mb-5 leading-3 text-xs`}>
            <div
              dangerouslySetInnerHTML={{ __html: data?.termsCondition || '' }}
            />
          </div>
          <div className={styles.footer}>
            <span className={`${styles.date} text-xxs`}>
              {data.startDateShow} {data.startMonthShow} {data.startYearShow} -{' '}
              {data.endDateShow} {data.endMonthShow} {data.endYearShow}
            </span>
            <Button
              className={`${styles.buttonGetCoupon}  rounded-sm bg-primary p-0 lg:text-sm xs:text-xs`}
              variant="outlined"
              sx={{
                color: 'white',
                background: '#4E1F66',
                borderColor: '#4E1F66',
                '&:hover': {
                  background: '#4E1F66',
                },
              }}
              onClick={() => {
                getCoupon(`${data.id}`);
              }}
            >
              {t('getcoupon')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
