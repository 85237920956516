import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import {
  createTheme,
  ThemeProvider,
  Theme,
  useTheme,
} from '@mui/material/styles';
import * as showDialog from '@/utils/showDialog';
import Loading from '../Layout/handler/Loading';
import {
  clearResult,
  getIssueFromCodeResultsSelector,
  getIssueFromCodeQuery,
  isLoadingIssueFromCodeSelector,
} from '@/store/slices/getIssueFromCodeSlice';
import { useAppDispatch } from '@/store/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { getRedirectQuery } from '@/utils/theone';

interface Props {
  title: string | null;
}
const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '--TextField-brandBorderColor': '#E0E3E7',
            '--TextField-brandBorderHoverColor': '#E0E3E7',
            '--TextField-brandBorderFocusedColor': '#E0E3E7',
            '& label.Mui-focused': {
              color: 'var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'var(--TextField-brandBorderColor)',
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderHoverColor)',
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
    },
  });
export default function UseCoupon({ title }: Props) {
  const { t, i18n } = useTranslation();
  const { t: t_product } = useTranslation('product');

  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState('');
  const [isClick, setIsClick] = useState(false);
  const router = useRouter();
  const getIssueFromCodeResults = useSelector(getIssueFromCodeResultsSelector);
  const isLoadingIssueFromCode = useSelector(isLoadingIssueFromCodeSelector);

  const buttonStyle = {
    height: '2rem',
    borderRadius: '0px',
  };
  const outerTheme = useTheme();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsClick(true);
    if (inputValue && inputValue !== '') {
      dispatch(
        getIssueFromCodeQuery({ lang: i18n.language, code: inputValue }),
      );
    } else {
      setIsClick(false);
      showDialog.showErrorWithOkButton({
        text: t('pleaseenterthecode'),
      });
    }
  };

  useEffect(() => {
    if (!isLoadingIssueFromCode && isClick) {
      if (getIssueFromCodeResults.status === 401) {
        showDialog
          .showMessageWithButton({
            text: t('pleaseloginbeforegetcode'),
            image: '/images/svg/success_circle.svg',
            primaryButton: {
              name: t('signin'),
              callBack: () => {
                router.push(
                  `/${router.locale}/signin${getRedirectQuery(router.asPath)}`,
                );
              },
            },
            secondaryButton: {
              name: t('cancel'),
            },
          })
          .then(() => {
            dispatch(clearResult());
          });
      } else {
        if (getIssueFromCodeResults.iserror == false) {
          showDialog
            .showMessageWithButton({
              text: t('congratulationsyouhavecoupon'),
              primaryButton: {
                name: t('viewcoupon'),
                callBack: () => {
                  router.push(`/${router.locale}/profile/myvoucher`);
                },
              },
              secondaryButton: {
                name: t_product('close'),
              },
            })
            .then(() => {
              dispatch(clearResult());
            });
        } else {
          showDialog
            .showErrorWithOkButton({
              text: `${getIssueFromCodeResults.msg}`,
            })
            .then(() => {
              dispatch(clearResult());
            });
        }
      }
      setIsClick(false);
    }
  }, [
    dispatch,
    isLoadingIssueFromCode,
    getIssueFromCodeResults,
    router,
    t,
    t_product,
    isClick,
  ]);
  return (
    <div className="my-2 text-center">
      {isClick ? <Loading /> : ''}

      {title ? <h2>{title}</h2> : <></>}
      <ThemeProvider theme={customTheme(outerTheme)}>
        <TextField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={`${t('pleaseputcode')}`}
          variant="outlined"
          InputProps={{
            style: {
              ...buttonStyle,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
              fontSize: '0.75em',
              width: '300px',
              fontFamily: 'helvethaicax',
            },
          }}
        />
        <label htmlFor="outlined-button-file">
          <Button
            disabled={isClick}
            variant="outlined"
            component="span"
            className={`bg-black hover:bg-black text-white`}
            style={{
              ...buttonStyle,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
              backgroundColor: 'black',
              color: 'white',
              fontFamily: 'helvethaicax',
            }}
            onClick={handleSubmit}
          >
            <span
              className={`${isClick ? 'text-white' : 'text-white'} text-xs`}
            >
              {t('submitcoupon')}
            </span>
          </Button>
        </label>
      </ThemeProvider>
    </div>
  );
}
