import { Flashsale } from '@/models/HomePage.model';
import React, { useEffect, useState } from 'react';
import { useTranslation as translation } from 'next-i18next';
import SlickProductCard from '../common/SlickProductCard';
import { Grid } from '@mui/material';
import { getEventData } from '@/services/client/getEventDataService';
import { ProductModel } from '@/models/Product.model';

export default function FlashSale({ flashsale }: { flashsale?: Flashsale }) {
  const { i18n } = translation();
  const [flashSaleData, setFlashSaleData] = useState<ProductModel[]>();
  useEffect(() => {
    if (flashsale?.type) {
      getEventData({
        lang: i18n.language,
        hotcat: flashsale?.type,
        maxshow: flashsale?.showmax,
      }).then((res) => {
        const data = res && res.products ? res.products : [];
        setFlashSaleData(data);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flashsale?.type, i18n.language]);

  return (
    <>
      {flashSaleData && flashSaleData.length > 0 ? (
        <div className="my-1" id="flash-sale">
          <Grid
            container
            className="rounded"
            style={{
              backgroundImage: flashsale?.img ?? '#5EC0CA',
            }}
          >
            <Grid item xs={2} className="m-auto text-center"></Grid>
            <Grid item xs={10}>
              <div className="bg-white m-1">
                <SlickProductCard
                  products={flashSaleData}
                  slidesToShow={4}
                  slidesToScroll={4}
                  slidesToShowMobile={2}
                  slidesToScrollMobile={2}
                  autoplay={true}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
