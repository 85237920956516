import { Banner } from '@/models/HomePage.model';
import { Box, Grid, Skeleton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import Image from 'next/image';
import heroBannerClass from '@/styles/herobanner.module.css';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { customerIdSelector } from '@/store/slices/loginSlice';
import AppLink from '@/components/Layout/handler/AppLink';

export default function HeroBanner({
  name,
  banners,
}: {
  name: string;
  banners?: Banner[];
}) {
  const router = useRouter();
  const customerId = useSelector(customerIdSelector);
  const ratioDesktop = 64 / 19;
  const ratioMobile = 75 / 38;
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const bannerRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<Slider>(null);

  const handleAfterChange = (index: number) => {
    setCurrentIndex(index);
  };

  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };

  const goto = (index: number) => {
    setCurrentIndex(index);
    sliderRef.current?.slickGoTo(index);
  };

  const settings = banners
    ? {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        prevArrow: (
          <>
            <Box
              className={`bg-white shadow-lg cursor-pointer ${heroBannerClass.buttonPrevious}`}
              sx={{
                position: 'absolute',
                zIndex: 9,
                top: '50%',
                transform: 'translateY(-50%)',
              }}
              onClick={previous}
            >
              <Box
                className="h-6 w-6 sm:h-8 sm:w-8 p-1 sm:p-2"
                sx={{ lineHeight: '1rem' }}
              >
                <i className="bi bi-chevron-left"></i>
              </Box>
            </Box>
          </>
        ),
        nextArrow: (
          <>
            <Box
              className={`bg-white shadow-lg cursor-pointer ${heroBannerClass.buttonNext}`}
              sx={{
                position: 'absolute',
                zIndex: 9,
                right: '-1rem',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
              onClick={next}
            >
              <Box
                className="h-6 w-6 sm:h-8 sm:w-8 p-1 sm:p-2"
                sx={{ lineHeight: '1rem' }}
              >
                <i className="bi bi-chevron-right"></i>
              </Box>
            </Box>
          </>
        ),
        afterChange: handleAfterChange,
        appendDots: (dots: any) => (
          <Box
            style={{
              zIndex: 10,
              marginBottom: '1.5rem',
            }}
          >
            <Box
              className="block w-full"
              style={{ margin: '0px', lineHeight: '0.5rem' }}
            >
              {dots.map((e: any, i: number) => (
                <React.Fragment key={`dot-${i}`}>
                  <div
                    onClick={() => {
                      goto(i);
                    }}
                    className="bg-secondaryPurple inline-flex ml-0.5 lg:mx-1 cursor-pointer rounded-full w-1 h-1 lg:w-2 lg:h-2"
                    style={{
                      opacity: currentIndex == i ? 1 : 0.5,
                    }}
                  />
                </React.Fragment>
              ))}
            </Box>
          </Box>
        ),
      }
    : null;

  useEffect(() => {
    const banner = bannerRef.current;
    if (
      !banner ||
      !(window as any).dataLayer ||
      customerId === undefined ||
      !banners
    )
      return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (banners && banners[currentIndex]) {
          (window as any).dataLayer.push({ ecommerce: null });
          (window as any).dataLayer.push({
            event: 'promotionView',
            userId: customerId,
            ecommerce: {
              promoView: {
                promotions: [
                  {
                    name: banners[currentIndex].topic,
                    position: `${name}_${currentIndex + 1}`,
                  },
                ],
              },
            },
          });
        }

        observer.disconnect();
      }
    });

    observer.observe(banner);

    return () => {
      if (banner && observer) {
        observer.unobserve(banner);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, customerId, banners]);

  const handleBannerClick = (banner: Banner, index: number) => {
    if ((window as any).dataLayer) {
      (window as any).dataLayer.push({ ecommerce: null });
      (window as any).dataLayer.push({
        event: 'promotionClick',
        userId: customerId,
        ecommerce: {
          promoClick: {
            promotions: [
              {
                name: banner.topic,
                position: `${name}_${index + 1}`,
              },
            ],
          },
        },
      });
    }
    if (banner.ahref) {
      window.open(`/${router.locale}/${banner.url}`, '_blank');
    } else {
      router.push(`/${router.locale}/${banner.url}`);
    }
  };

  return (
    <div ref={bannerRef}>
      <Grid container position="relative">
        <Grid item xs={12}>
          {banners ? (
            <Slider ref={sliderRef} {...settings}>
              {banners.map((e, i) => (
                <React.Fragment key={`slider-${i}`}>
                  <div
                    className="hidden md:inline"
                    style={{ cursor: 'pointer' }}
                  >
                    <div
                      onClick={(event: any) => {
                        event.preventDefault();
                        handleBannerClick(e, i);
                      }}
                    >
                      {i === 0 ||
                      process.env.NEXT_PUBLIC_CMS_USE_NEXT_IMAGE !== '1' ? (
                        // eslint-disable-next-line @next/next/no-img-element
                        <Image
                          src={e.img}
                          alt={e.topic}
                          width={1280}
                          height={380}
                          className="w-full"
                          style={{ height: 'auto' }}
                          loading="eager"
                        />
                      ) : (
                        <Image
                          src={e.img}
                          alt={e.topic}
                          width={1280}
                          height={380}
                          className="w-full"
                          style={{ height: 'auto' }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="block md:hidden">
                    <AppLink
                      key={`m-${i}`}
                      href={e.url}
                      target={e.ahref ? '_blank' : ''}
                    >
                      {i === 0 ||
                      process.env.NEXT_PUBLIC_CMS_USE_NEXT_IMAGE !== '1' ? (
                        // eslint-disable-next-line @next/next/no-img-element
                        <Image
                          src={e.imgMobile ?? e.img}
                          alt={e.topic}
                          width={750}
                          height={380}
                          className="w-full"
                          style={{ height: 'auto' }}
                          loading="eager"
                        />
                      ) : (
                        <Image
                          src={e.imgMobile ?? e.img}
                          alt={e.topic}
                          width={750}
                          height={380}
                          className="w-full"
                          style={{ height: 'auto' }}
                        />
                      )}
                    </AppLink>
                  </div>
                </React.Fragment>
              ))}
            </Slider>
          ) : (
            <>
              <div
                className="hidden md:block"
                style={{
                  height: `${100 / ratioDesktop}vw`,
                  maxHeight: 1360 / ratioDesktop,
                }}
              >
                <Skeleton
                  className="hidden md:block"
                  variant="rectangular"
                  width="100%"
                  height="100%"
                  sx={{ maxHeight: 1360 / ratioDesktop }}
                />
              </div>
              <div
                className="block md:hidden"
                style={{ height: `${100 / ratioMobile}vw` }}
              >
                <Skeleton
                  className="block md:hidden"
                  variant="rectangular"
                  width="100%"
                  height="100%"
                />
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
