import { Grid } from '@mui/material';
import React, { useState } from 'react';
import styles from '../../styles/bundle.module.css';
import SaleBadge from '../ProductCard/badge/SaleBadge';
import SlickProductCard from '../common/SlickProductCard';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import AddToCartButton from '../ProductCard/AddToCartButton';
import { SkuWithQty } from '@/models/AddToCart.model';

interface Props {
  index: string;
  element: any;
  convertedProducts: any;
  allorgprice: string;
  allplppprice: string;
  setid: string;
  skuQtyList?: SkuWithQty[];
  isSlide?: Boolean;
}
export default function BundleImageCard({
  index,
  element,
  convertedProducts,
  allorgprice,
  allplppprice,
  setid,
  skuQtyList,
  isSlide,
}: Props) {
  const { t } = useTranslation('product');
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <Grid key={`${index}`} item xs={12} lg={isSlide ? 12 : 4}>
      <div
        className="bg-grayLight1 p-1 m-1"
        style={{
          borderRadius: '5px',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {element.keyword && element.isLine == '1' ? (
          <div className={`${styles.cornerribbon} bg-primary text-white`}>
            {element.keyword}
          </div>
        ) : (
          <></>
        )}
        {element &&
        element.bundlesku &&
        element.bundlesku[0] &&
        Array.isArray(element.bundlesku[0]) &&
        element.bundlesku[0][0].allpercent ? (
          <div className="absolute top-2 right-2">
            <SaleBadge
              discountPercent={Number(element.bundlesku[0][0].allpercent)}
            />
          </div>
        ) : (
          <></>
        )}
        <div
          className={`${styles.bundleImage} bg-primary text-white`}
          style={{
            backgroundImage: `url(${element.imgBundle})`,
            visibility: isImageLoaded ? 'visible' : 'hidden', // Hide the div until the image is loaded
          }}
        >
          <Image
            src={element.imgBundle}
            alt="Bundle Image"
            style={{ visibility: 'hidden' }}
            onLoad={() => setIsImageLoaded(true)} // Set isImageLoaded to true when the image is loaded
            width={10}
            height={10}
          />
        </div>
        <Grid
          container
          justifyItems={'center'}
          justifyContent={'center'}
          sx={{ marginBottom: '20px', background: 'white' }}
          className="bg-white"
        >
          <Grid item xs={12} lg={12} className="">
            <SlickProductCard
              products={convertedProducts}
              title={''}
              slidesToShow={2}
              slidesToScroll={1}
              slidesToShowMobile={2}
              slidesToScrollMobile={1}
              isBundle={true}
              isBundleImage={true}
              isNotInfinite={false}
              isDotsMobile={false}
            />
          </Grid>
          <Grid item xs={11} lg={11} className="border-t"></Grid>
          <Grid item xs={12} lg={12}>
            <Grid
              container
              justifyItems={'center'}
              justifyContent={'center'}
              alignContent={'center'}
              alignItems={'center'}
              className="px-2"
            >
              <Grid item xs={12} lg={8} className="text-center leading-3 py-2">
                <div>
                  <span className="text-xs pr-2">฿{allorgprice}</span>
                  <span className="text-redPrice">{t('onlyleft')}</span>
                </div>
                <span>
                  <span className="pr-1 text-redPrice">฿</span>
                  <span className="text-redPrice pr-1 text-3xl font-bold leading-3">
                    {allplppprice}
                  </span>
                  <span className="text-xs text-grayDark">/SET</span>
                </span>
              </Grid>
              <Grid item xs={12} lg={4} className="lg:py-2 xs:pb-2">
                <AddToCartButton setId={setid} skuQtyList={skuQtyList} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
